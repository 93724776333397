import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, map } from 'rxjs';
import { take } from 'rxjs/operators';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService, URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import { ConfirmationModalComponent, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { InvoiceError, Invoicer, SalesInvoiceDraft, SalesInvoiceType } from '@dougs/sales-invoice/dto';
import { DEFAULT_VAT_RATE, SalesInvoicerStateService, SalesInvoiceStateService } from '@dougs/sales-invoice/shared';
import { UserStateService } from '@dougs/user/shared';
import { FinalizationErrorModalComponent } from '../../modals/finalization-error-modal/finalization-error-modal.component';
import { FinalizationModalComponent } from '../../modals/finalization-modal/finalization-modal.component';
import { FirstFinalizationModalComponent } from '../../modals/first-finalization-modal/first-finalization-modal.component';
import { SalesInvoiceFilterComponentService } from '../sales-invoice-filter-component.service';

@Injectable()
export class SalesInvoiceDraftComponentService {
  constructor(
    private readonly salesInvoiceStateService: SalesInvoiceStateService,
    private readonly salesInvoicerStateService: SalesInvoicerStateService,
    private readonly salesInvoiceFilterComponentService: SalesInvoiceFilterComponentService,
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    @Inject(Window) private readonly window: Window,
  ) {}

  showInlineVatRate(invoice: SalesInvoiceDraft): boolean {
    return this.hasMultipleVatRate(invoice) || !invoice.metadata.hasGlobalVatRate;
  }

  hasMultipleVatRate(invoice: SalesInvoiceDraft): boolean {
    return !!invoice.vatSummary?.length && invoice.vatSummary.length > 1;
  }

  addGlobalVat(invoice: SalesInvoiceDraft): SalesInvoiceDraft {
    return {
      ...invoice,
      metadata: {
        hasGlobalVatRate: true,
      },
      lines: invoice.lines.map((line) => ({ ...line, vatRate: DEFAULT_VAT_RATE })),
    };
  }

  getCommonVatRate(invoice: SalesInvoiceDraft): number {
    if (invoice.lines.length > 0 && invoice.lines.every((line) => line.vatRate === invoice.lines[0].vatRate)) {
      return invoice.lines[0].vatRate;
    }
    return DEFAULT_VAT_RATE;
  }

  async deleteInvoice(invoice: SalesInvoiceDraft, shouldReturnToList = false): Promise<void> {
    const toDelete: boolean = await toPromise(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Supprimer la facture',
            body: 'Êtes-vous sûr de vouloir supprimer cette facture ? Cette action est définitive.',
            noText: 'Annuler',
            yesText: 'Oui, supprimer la facture',
          },
        })
        .afterClosed$.pipe(
          take(1),
          map((res: OverlayCloseEvent<unknown>) => !!res.data),
        ),
    );
    if (toDelete && invoice) {
      await this.salesInvoiceStateService.deleteSalesInvoiceDraft(invoice);
      if (shouldReturnToList) {
        await this.returnToList();
      }
    }
  }

  async downloadOffer(invoice: SalesInvoiceDraft): Promise<void> {
    const invoiceErrors: InvoiceError[] | null = await this.salesInvoiceStateService.canFinalizeOffer(invoice);
    if (invoiceErrors && invoiceErrors.length > 0) {
      this.modalService.open(FinalizationErrorModalComponent, {
        data: { type: 'offer', invoiceErrors },
      });
    } else {
      this.metricsService.pushMixpanelEvent('Invoicing Offer downloaded', { Type: 'Offer' });
      this.metricsService.pushIntercomEvent('Invoicing Offer downloaded', { Type: 'Offer' });
      this.window.open(
        `/companies/${invoice.companyId ?? invoice.invoicerId}/sales-invoices-drafts/${
          invoice.id
        }/actions/download-offer`,
        '_blank',
      );
    }
  }

  async finalizeInvoice(invoice: SalesInvoiceDraft): Promise<void> {
    const invoiceErrors: InvoiceError[] | null = await this.salesInvoiceStateService.canFinalizeInvoice(invoice);
    if (invoiceErrors && invoiceErrors.length > 0) {
      this.metricsService.pushMixpanelEvent('Invoicing finalised Invoice failed', {
        'Failed reasons': invoiceErrors.map((error) => error.message),
      });

      this.metricsService.pushIntercomEvent('Invoicing finalised Invoice failed', {
        'Failed reasons': invoiceErrors.map((error) => error.message),
      });

      this.modalService.open(FinalizationErrorModalComponent, {
        data: { type: 'invoice', invoiceErrors },
      });
    }
    const invoicer: Invoicer = await lastValueFrom(this.salesInvoicerStateService.invoicer$.pipe(take(1)));

    let finalizedInvoiceDraft: SalesInvoiceDraft | null | undefined;
    if (invoiceErrors && invoiceErrors.length === 0) {
      if (invoicer.lastIssuedInvoiceNumber) {
        finalizedInvoiceDraft = (
          await toPromise(
            this.modalService
              .open(FinalizationModalComponent, {
                data: {
                  invoice,
                  invoiceNumber: invoicer.lastIssuedInvoiceNumber + 1,
                  date: invoice.date,
                  totalAmount: invoice.totalAmountWithVat,
                },
              })
              .afterClosed$.pipe(take(1)),
          )
        )?.data as SalesInvoiceDraft | null | undefined;
      } else {
        finalizedInvoiceDraft = (
          await toPromise(
            this.modalService
              .open(FirstFinalizationModalComponent, {
                data: {
                  invoice,
                  invoicer,
                  date: invoice.date,
                  firstName: this.userStateService.activeUser.profile?.firstName,
                },
              })
              .afterClosed$.pipe(take(1)),
          )
        )?.data as SalesInvoiceDraft | null | undefined;
      }
    }
    if (finalizedInvoiceDraft?.salesInvoiceId) {
      this.salesInvoiceFilterComponentService.setInvoiceType(SalesInvoiceType.FINALIZED);
      await this.router.navigateByUrl(
        this.routingService
          .createUrl([URL.SALES_INVOICE], true, {
            queryParams: { salesInvoiceId: finalizedInvoiceDraft?.salesInvoiceId },
          })
          .toString(),
      );
    }
  }

  async returnToList(): Promise<void> {
    this.salesInvoiceFilterComponentService.setInvoiceType(SalesInvoiceType.DRAFT);
    await this.router.navigateByUrl(this.routingService.createUrl([URL.SALES_INVOICE]).toString());
  }

  hasError(invoiceErrors: InvoiceError[], field: string): boolean {
    return invoiceErrors.some((invoiceError) => invoiceError.field === field);
  }

  getDiscountErrorMessage(invoiceErrors: InvoiceError[], index: number): string {
    const error = invoiceErrors.find((invoiceError) => invoiceError.field === 'line.' + index + '.discount');
    return error ? error.message.replace(`Ligne de facturation ${index + 1} : `, '') : '';
  }
}
